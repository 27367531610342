import { type ElementRef, forwardRef, type ReactNode } from 'react'
import { OverlayArrow, Tooltip as TooltipPrimitive, TooltipTrigger, type TooltipProps as TooltipPropsPrimitive } from 'react-aria-components'
import { cn } from '#app/utils/tailwind-merge.ts'

type TooltipProps = TooltipPropsPrimitive & {
	side?: 'bottom' | 'top' | 'left' | 'right'
	children: ReactNode
}

/**
 * Tooltip component.
 *
 * @component
 * @example
 * ```jsx
 * <TooltipTrigger>
 * 	<Button>Create account</Button>
 * 	<Tooltip>
 * 		Tooltip's content
 * 	</Tooltip>
 * </TooltipTrigger>
 * ```
 *
 * @param {offset} [offset] - Spacing between the tooltip and the trigger across the main axis.
 * @param {crossOffset} [crossOffset] - Spacing between the tooltip and the trigger across the cross axis.
 * @param {string} [side='bottom'] - Side of the screen to anchor the tooltip to. Can be `bottom` (default), `top`, `left`, or `right`.
 * @param {ReactNode | (values: TooltipRenderProps) => ReactNode} [children] - Children of the tooltip. A function may be provided to access a function to close the tooltip.
 * @param {string} [className] - Additional CSS class names.
 * @param {React.HTMLAttributes<HTMLDivElement>} [props] - Additional HTML attributes.
 * @returns {JSX.Element} The Tooltip component.
 */
const Tooltip = forwardRef<ElementRef<typeof TooltipPrimitive>, TooltipProps>(({ children, offset, crossOffset, side = 'bottom', className, ...props }, forwardedRef) => {
	const placement =
		side === 'left' ? 'start'
		: side === 'right' ? 'end'
		: side
	return (
		<TooltipPrimitive
			ref={forwardedRef}
			className={cn('tooltip', className)}
			placement={placement}
			offset={offset ? Number(offset) : undefined}
			crossOffset={crossOffset ? Number(crossOffset) : undefined}
			{...props}
		>
			<OverlayArrow className="tooltip-arrow">
				<svg width="8" height="8" viewBox="0 0 8 8">
					<path d="M0 0 L4 4 L8 0" />
				</svg>
			</OverlayArrow>
			{children}
		</TooltipPrimitive>
	)
})
Tooltip.displayName = 'Tooltip'

export { TooltipTrigger, Tooltip }
